import API from "../api";
import {pipe} from "fp-ts/function";
import {from, Observable} from "rxjs";
import * as OBS from "fp-ts-rxjs/Observable";
import {IGroup} from "../model/group.model";

const BASE_PATH = 'group'

export const fetchGroups = (schoolId: number, search?: string): Observable<IGroup[]> => {
    return pipe(
        from(
            API.get(BASE_PATH + "/all", {
                params: {
                    schoolId,
                    search
                }
            })
        ),
        OBS.map((res): IGroup[] => res.data)
    )
}
