import {Navigate, Outlet, useLocation} from 'react-router-dom';
import * as React from "react";
import {useSelector} from "react-redux";
import {RootState} from "../store";

const PrivateRoute = () => {
    const location = useLocation()

    const isLogged = useSelector((state: RootState) => {
        return state.login.isLogged
    })

    return isLogged ? <Outlet/> : location.pathname.includes('-step') ?
        <Navigate to={{pathname: "/login", search: "?redirect=true"}} replace state={{from: location}}/> :
        <Navigate to={{pathname: "/login"}} replace state={{from: location}}/>
}

export default PrivateRoute
