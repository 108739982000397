import {pipe} from "fp-ts/function"
import * as RA from "fp-ts/ReadonlyArray"
import jsPDF from "jspdf"
import autoTable, {ColumnInput, RowInput, Styles} from "jspdf-autotable"
import {ISchool} from "../../model/school.model"
import {ISubscriptionData} from "../../model/subscription.model"
import {sequenceS} from "fp-ts/Apply"
import * as OBS from "fp-ts-rxjs/Observable"
import {exportByEvent, exportBySchoolAndEvent} from "../../service/export.service"
import {getSchoolTotalCostByEvent} from "../../service/school.service"
import {SchoolCounter} from "../../model/export.model"
import {pageLoadingSActions} from "../../store/page-loading.state";
import {AnyAction} from "@reduxjs/toolkit";
import {Dispatch} from "react";

export const exportSchoolSubscriptions = (dispatch: Dispatch<AnyAction>, eventId: number | string, school: ISchool, eventName: string): void => {
    dispatch(pageLoadingSActions.showLoading())
    pipe(
        {
            cost: getSchoolTotalCostByEvent(eventId, school.id!),
            subscriptions: exportBySchoolAndEvent(eventId, school.id!)
        },
        sequenceS(OBS.Monad)
    ).subscribe(result => {
        const cost = result.cost
        const data = result.subscriptions

        const doc = new jsPDF()
        doc.text('Evento ' + eventName, 14, 15)
        doc.text('Associazione ' + school.schoolName, 14, 30)
        doc.text('Totale iscrizioni ' + cost.cost + ' €', 14, 45)

        if (cost.iban) {
            const pageWidth = doc.internal.pageSize.getWidth()
            const maxWidth = pageWidth - 28
            const text = cost.iban
            const lines = doc.splitTextToSize(text, maxWidth)

            doc.text(lines, 14, 55)
        }

        let finalY = 75

        const solo = data.filter(subscription => subscription.disciplineType === 1)
        const duo = data.filter(subscription => subscription.disciplineType === 2)
        const group = data.filter(subscription => subscription.disciplineType > 2)

        if (solo.length > 0) {
            doc.text('SOLO', 14, 75)
            autoTable(doc, {
                columns: [
                    {header: 'Atleta', dataKey: 'competitor1'},
                    {header: 'Disciplina', dataKey: 'discipline'},
                    {header: 'Categoria', dataKey: 'category'},
                    {header: 'Classe', dataKey: 'class'},
                    {header: 'Nome coreografia', dataKey: 'choreographyName'},
                    {header: 'Costo', dataKey: 'cost'}
                ],
                body: extractedData(decorateISubscriptionData(solo)),
                startY: 90,
                columnStyles: {
                    3: {
                        halign: "center"
                    },
                    5: {
                        halign: "right"
                    }
                }
            })

            finalY = (doc as any).lastAutoTable.finalY
        }

        if (duo.length > 0) {
            doc.text('COPPIA/DUO', 14, finalY + 15)
            finalY = finalY + 15

            autoTable(doc, {
                columns: [
                    {header: 'Primo atleta', dataKey: 'competitor1'},
                    {header: 'Secondo atleta', dataKey: 'competitor2'},
                    {header: 'Disciplina', dataKey: 'discipline'},
                    {header: 'Categoria', dataKey: 'category'},
                    {header: 'Classe', dataKey: 'class'},
                    {header: 'Nome coreografia', dataKey: 'choreographyName'},
                    {header: 'Costo', dataKey: 'cost'}
                ],
                body: extractedData(decorateISubscriptionData(duo)),
                startY: finalY + 15,
                columnStyles: {
                    4: {
                        halign: "center"
                    },
                    6: {
                        halign: "right"
                    }
                }
            })

            finalY = (doc as any).lastAutoTable.finalY
        }

        if (group.length > 0) {
            doc.text('GRUPPO', 14, finalY + 15)
            finalY = finalY + 15

            autoTable(doc, {
                columns: [
                    {header: 'Gruppo - Nome coreografia - Numero atleti', dataKey: 'groupName'},
                    {header: 'Disciplina', dataKey: 'discipline'},
                    {header: 'Categoria', dataKey: 'category'},
                    {header: 'Classe', dataKey: 'class'},
                    {header: 'Costo', dataKey: 'cost'}
                ],
                body: extractedData(decorateISubscriptionData(group)),
                startY: finalY + 15,
                columnStyles: {
                    3: {
                        halign: "center"
                    },
                    4: {
                        halign: "right"
                    }
                }
            })
        }

        doc.save('Lista iscritti ' + eventName + '.pdf')
        dispatch(pageLoadingSActions.hideLoading())
    })
}

const decorateISubscriptionData = (data: ISubscriptionData[]) => {
    return pipe(
        data,
        RA.map(row => ({
            ...row,
            cost: `${row.cost} €`
        }))
    )
}

export const exportSubscriptions = (dispatch: Dispatch<AnyAction>, eventId: number, eventName: string): void => {
    dispatch(pageLoadingSActions.showLoading())
    exportByEvent(eventId).subscribe(data => {
        const doc = new jsPDF()
        doc.text('Evento ' + eventName, 14, 15)

        doc.text('Totale persone iscritte: ' + data.total, 14, 30)
        doc.text('Iscritti discipline Singoli: ' + data.counters.solo, 14, 40)
        doc.text('Iscritti discipline Coppia/Duo: ' + data.counters.duo, 14, 50)
        doc.text('Iscritti discipline Gruppi/Formazione: ' + data.counters.group, 14, 60)

        doc.addPage()

        doc.text('Iscritti per scuola', 14, 15)

        autoTable(doc, {
            columns: [
                {header: 'Nome', dataKey: 'name'},
                {header: 'Atleti', dataKey: 'count'},
                {header: 'Totale iscrizioni', dataKey: 'totalCost'},
            ],
            body: extractedData(decorateITotalSubscriptions(data.schools)),
            startY: 30,
            columnStyles: {
                0: {
                    cellWidth: 110
                },
                1: {
                    cellWidth: 30,
                    halign: "right"
                },
                2: {
                    cellWidth: 30,
                    halign: "right"
                }
            }
        })

        doc.addPage()

        doc.text('Lista iscritti per disciplina', 14, 15)

        let finalY = 40

        const map: { [key: string]: ISubscriptionData[] } = {}

        data.subscriptions.forEach(value => {
            const key = value.discipline + ' ' + value.category + ' ' + value.class
            let oldValue = map[key]
            if (!oldValue) {
                oldValue = []
            }

            oldValue.push(value)
            map[key] = oldValue
        })

        Object.keys(map).forEach(function (key) {
            if (finalY + 45 > doc.internal.pageSize.height) {
                doc.addPage()
                finalY = 15
            }

            doc.text(key, 14, finalY)

            const value = map[key]
            const firstValue = value[0]

            let columns: ColumnInput[]
            let columnStyles: {
                [key: string]: Partial<Styles>
            }

            const disciplineType = firstValue.disciplineType

            if (disciplineType == 1) {
                columns = [
                    {header: 'Atleta', dataKey: 'competitor1'},
                    {header: 'Scuola', dataKey: 'schoolName'}
                ]

                columnStyles = {
                    0: {
                        cellWidth: 90
                    },
                    1: {
                        cellWidth: 90
                    }
                }
            } else if (disciplineType == 2) {
                columns = [
                    {header: 'Primo atleta', dataKey: 'competitor1'},
                    {header: 'Secondo atleta', dataKey: 'competitor2'},
                    {header: 'Scuola', dataKey: 'schoolName'}
                ]

                columnStyles = {
                    0: {
                        cellWidth: 60
                    },
                    1: {
                        cellWidth: 60
                    },
                    2: {
                        cellWidth: 60
                    }
                }
            } else {
                columns = [
                    {header: 'Gruppo', dataKey: 'groupName'},
                    {header: 'Scuola', dataKey: 'schoolName'}
                ]

                columnStyles = {
                    0: {
                        cellWidth: 90
                    },
                    1: {
                        cellWidth: 90
                    }
                }
            }

            autoTable(doc, {
                columns,
                body: extractedData(map[key]),
                startY: finalY + 5,
                columnStyles,
                pageBreak: 'avoid'
            })

            finalY = (doc as any).lastAutoTable.finalY + 15
        })

        doc.save('Totale lista iscritti ' + eventName + '.pdf')
        dispatch(pageLoadingSActions.hideLoading())
    })
}

const decorateITotalSubscriptions = (data: ReadonlyArray<SchoolCounter>) => {
    return pipe(
        data,
        RA.map(row => ({
            ...row,
            totalCost: `${row.totalCost} €`
        }))
    )
}

const extractedData = (subs: ReadonlyArray<any>): RowInput[] =>
    pipe(
        subs,
        RA.map(sub => JSON.parse(JSON.stringify(sub))),
        RA.toArray
    )