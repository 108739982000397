import React from "react";
import "./dashboard.page.css"
import {Card, Col, Row} from "antd";
import Meta from "antd/es/card/Meta";
import {useNavigate} from "react-router-dom";

const DashboardPage = () => {
    const navigate = useNavigate()

    const navigateTo = () => {
        navigate('/createRegistration')
    }

    return (
        <div className="dashboard">
            TESTO TESTO TESTO
            METTERE QUI:
            <ul>
                <li>titolo</li>
                <li>descrizione</li>
                <li>lista eventi</li>
                <li>altro</li>
            </ul>
            YEEE SONO UN ADMIN!!!!!!!
        </div>
    );
}


export default DashboardPage
