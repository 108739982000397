import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface RegistrationErrorModalState {
    isVisible: boolean
    message?: string
}

export const registrationErrorModalSlice = createSlice({
    name: 'notification',
    initialState: {isVisible: false} as RegistrationErrorModalState,
    reducers: {
        show(state, action: PayloadAction<string>) {
            state.isVisible = true
            state.message = action.payload
        },
        hide(state) {
            state.isVisible = false
        }
    }
})

export const registrationErrorModalActions = registrationErrorModalSlice.actions
export const registrationErrorModalReducer = registrationErrorModalSlice.reducer

