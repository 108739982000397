import './error.page.css'

const ErrorPage = () => {
    return (
        <div className="error">
            <h1>Non c'è nulla da vedere qui.</h1>
        </div>
    )
}

export default ErrorPage