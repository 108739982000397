import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {CustomAxiosError} from "../api";

export interface NotificationState {
    notification: {
        type: 'success' | 'info' | 'warning' | 'error'
        message: string
        code?: number
    }
}

export const notificationSlice = createSlice({
    name: 'notification',
    initialState: {} as NotificationState,
    reducers: {
        success(state, action: PayloadAction<string>) {
            state.notification = {
                type: "success",
                message: action.payload
            }
        },
        info(state, action: PayloadAction<string>) {
            state.notification = {
                type: "info",
                message: action.payload
            }
        },
        warning(state, action: PayloadAction<string>) {
            state.notification = {
                type: "warning",
                message: action.payload
            }
        },
        error(state, action: PayloadAction<CustomAxiosError>) {
            state.notification = {
                type: "error",
                message: action.payload.message,
                code: action.payload.code
            }
        }
    }
})

export const notificationActions = notificationSlice.actions
export const notificationReducer = notificationSlice.reducer

