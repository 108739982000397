import API from "../api";
import {pipe} from "fp-ts/function";
import {from, Observable} from "rxjs";
import * as OBS from "fp-ts-rxjs/Observable";
import {ICompetitor} from "../model/competitor.model";

const BASE_PATH = 'competitor'

export const fetchCompetitors = (schoolId: number, search?: string): Observable<ReadonlyArray<ICompetitor>> => {
    return pipe(
        from(
            API.get(BASE_PATH + "/all", {
                params: {
                    schoolId,
                    search
                }
            })
        ),
        OBS.map((res): ReadonlyArray<ICompetitor> => res.data)
    )
}

export const fetchCompetitorsByGroupId = (groupId: number): Observable<ReadonlyArray<ICompetitor>> => {
    return pipe(
        from(
            API.get(BASE_PATH + "/byGroupId", {
                params: {
                    groupId
                }
            })
        ),
        OBS.map((res): ReadonlyArray<ICompetitor> => res.data)
    )
}

export const getCompetitor = (id: string): Observable<ICompetitor> => {
    return pipe(
        from(
            API.get(BASE_PATH, {
                params: {
                    id
                }
            })
        ),
        OBS.map((res): ICompetitor => res.data)
    )
}

export const updateCompetitor = (id: string, name: string, surname: string): Observable<ICompetitor> => {
    return pipe(
        from(
            API.put(BASE_PATH, {
                id,
                name,
                surname
            })
        ),
        OBS.map((res): ICompetitor => res.data)
    )
}