import {useSelector} from 'react-redux';
import {Navigate, useLocation} from 'react-router-dom';

import * as React from "react";
import {RootState} from "../../../store";

// @ts-ignore
const FirstStepGuard = ({children}) => {
    const location = useLocation()

    const school = useSelector((state: RootState) => state.school)

    return school.id ? <Navigate to="../second-step" replace state={{from: location}}/> : children
}

export default FirstStepGuard
