export interface IDiscipline {
    readonly id: number
    readonly description: string
    readonly disciplineType: DisciplineType
}

export type DisciplineType = 1 | 2 | 3 | 4 | 5

interface DisciplineTypeWrapper {
    readonly label: string
    readonly type: DisciplineType
}

export const disciplineTypeData: ReadonlyArray<DisciplineTypeWrapper> = [
    {
        label: "Solo",
        type: 1,
    },
    {
        label: "Coppia/Duo",
        type: 2,
    },
    {
        label: "Gruppo",
        type: 3,
    }
]

