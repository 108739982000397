import CodiceFiscale from "codice-fiscale-js";
import {ValidatorRule} from "rc-field-form/lib/interface";

export const fiscalCodeFormItemValidator = (): ValidatorRule => ({
    validator: (_, value) => {
        const trimmedValue: string = value ? value.trim() : undefined
        if(!trimmedValue || CodiceFiscale.check(trimmedValue)){
            return Promise.resolve()
        }else{
            return Promise.reject('Il codice fiscale non valido');
        }
    },
    message: 'Il codice fiscale non valido',
})