import {useSelector} from 'react-redux';
import {Navigate, Outlet} from 'react-router-dom';

import {RootState} from "../store";
import * as React from "react";

const PrivateAdminRoute = () => {
    const [isLogged, isAdmin] = useSelector((state: RootState) => {
        return [state.login.isLogged, state.login.isAdmin]
    })

    if (!isLogged && !isAdmin) {
        return <Navigate to="/home"/>
    }

    return <Outlet />
}

export default PrivateAdminRoute
