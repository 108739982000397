import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IUser} from "../model/user.model";

export const USER_LOCAL_STORAGE = 'LOGIN'

export interface LoginState {
    user: IUser | undefined
    isLogged: boolean
    isAdmin: boolean | undefined
    isUser: boolean | undefined
}

const initialState: LoginState = {
    user: undefined,
    isLogged: false,
    isAdmin: false,
    isUser: false
}

const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        login(state, action: PayloadAction<IUser>) {
            state.user = action.payload
            state.isLogged = true
            state.isAdmin = action.payload.authority === 'ROLE_ADMIN'
            state.isUser = action.payload.authority === 'ROLE_USER'

            localStorage.setItem(USER_LOCAL_STORAGE, JSON.stringify(state))
        },
        initialize(state, action: PayloadAction<number>){
            state = {
                ...state,
                user: {
                    ...state.user!,
                    initialized: true,
                    schoolId: action.payload
                }
            }
            localStorage.setItem(USER_LOCAL_STORAGE, JSON.stringify(state))
        },
        logout(state) {
            state.user = undefined
            state.isLogged = false
            state.isAdmin = undefined
            state.isUser = undefined

            localStorage.clear()
        }
    }
})

export const loginActions = loginSlice.actions
export const loginReducer = loginSlice.reducer

export const loadLoginFromLocalStorage = () => {
    try {
        const user = localStorage.getItem(USER_LOCAL_STORAGE)
        return user ? JSON.parse(user) : undefined
    } catch (e) {
        return undefined
    }
}
