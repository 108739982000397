import {Button, Modal} from "antd";
import React from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../store";

interface Props {
    readonly isOpen: boolean
    readonly onCloseModal: () => void
}

const RegistrationErrorModal = (props: Props) => {

    const registrationErrorModal = useSelector((state: RootState) => {
        return state.registrationErrorModal
    })

    return <Modal title="Avviso" open={props.isOpen} onCancel={props.onCloseModal} footer={[
        <Button key="submit" type="primary" onClick={props.onCloseModal}>
            Chiudi
        </Button>
    ]}>
        <p style={{textAlign: 'justify'}}>{registrationErrorModal.message}</p>
    </Modal>
}

export default RegistrationErrorModal