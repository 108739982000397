import {Col, Form, Input, Modal, Row, Table} from "antd"
import React, {useEffect, useState} from "react"
import {useSelector} from "react-redux"
import {RootState} from "../../store"
import Column from "antd/es/table/Column"
import {debounceTime, distinctUntilChanged, Subject} from "rxjs"
import {fetchGroups} from "../../service/group.service";
import {IGroup} from "../../model/group.model";

interface GroupChooserModalProps {
    readonly isModalOpen: boolean
    readonly onChoosing: (group: IGroup) => void
    readonly onClose: () => void
}

interface GroupChooserFilters {
    readonly search?: string
}

const GroupChooserModal = (props: GroupChooserModalProps) => {
    const [loading, setLoading] = useState(false)
    const [groups, setgroups] = useState([] as ReadonlyArray<IGroup>)
    const [filters, setFilters] = useState({} as GroupChooserFilters)

    const [form] = Form.useForm()

    const school = useSelector((state: RootState) => state.school)

    const [onSearch$] = useState(() => new Subject());

    useEffect(() => {
        const subscription = onSearch$.pipe(
            debounceTime(500),
            distinctUntilChanged(),
        ).subscribe((value) => setFilters({search: value as string}));

        return () => subscription.unsubscribe()
    }, [])

    useEffect(() => {
        if (props.isModalOpen) {
            setLoading(true)

            fetchGroups(school.id!, filters.search).subscribe(value => {
                setgroups(value)
                setLoading(false)
            })
        }
    }, [filters.search, props.isModalOpen])

    const onSearchChange = (event: any) => onSearch$.next(event.target.value)

    const locale = {
        triggerAsc: 'Clicca per ordinare in modo ascendente',
        triggerDesc: 'Clicca per ordinare in modo discendente'
    }

    return (
        <>
            <Modal title="Scegli gruppo" open={props.isModalOpen} onCancel={props.onClose} footer={null}>
                <Form
                    form={form}
                    layout="vertical"
                    size="middle"
                    name="filtersForm"
                >
                    <Row gutter={100} justify="space-between">
                        <Col xs={24} md={12}>
                            <Form.Item
                                label="Ricerca"
                                key={"search"}
                                name="search"
                            >
                                <Input placeholder="Cerca per nome" type={"text"}
                                       onChange={(value) => onSearchChange(value)}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Table
                    id="groupsTable"
                    loading={loading}
                    onRow={(record) => {
                        return {
                            onClick: () => {
                                props.onChoosing(record)
                            }
                        }
                    }}
                    scroll={{x: "max-content"}}
                    dataSource={groups}
                    locale={locale}
                    rowClassName="cursor-pointer"
                    rowKey={(record) => record.id + ' ' + Math.random()}
                    pagination={{position: ['bottomCenter']}}>
                    <Column title="Nome" dataIndex="name" key="name"
                            sorter={(a: IGroup, b: IGroup) => a.name!.localeCompare(b.name!)}
                            sortDirections={['ascend', 'descend', 'ascend']}/>
                    <Column title="N componenti" dataIndex="cardinality" key="cardinality"/>
                </Table>
            </Modal>
        </>
    )
}

export default GroupChooserModal
