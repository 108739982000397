import {notification as notifier} from "antd"
import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {RootState} from "../../store"
import {registrationErrorModalActions} from "../../store/registration-error-modal.state";

const NotificationComponent = () => {
    const [api, contextHolder] = notifier.useNotification();

    const dispatch = useDispatch()

    const notification = useSelector((state: RootState) => {
        return state.notification.notification
    })

    useEffect(() => {
        if (notification) {
            switch (notification.type) {
                case "error":
                    if(notification.code && notification.code === 400){
                        dispatch(registrationErrorModalActions.show(notification.message))
                    } else {
                        api["error"]({
                            message: "Errore",
                            description: notification.message,
                            placement: "topRight",
                            duration: 10
                        });
                    }
                    break
                case "success":
                    api["success"]({
                        message: "Info",
                        description: notification.message,
                        placement: "topRight",
                        duration: 5
                    });
                    break
                case "warning":
                    api["warning"]({
                        message: "Attenzione",
                        description: notification.message,
                        placement: "topRight",
                        duration: 10
                    });
                    break
                case "info":
                default:
                    api["info"]({
                        message: "Info",
                        description: notification.message,
                        placement: "topRight",
                        duration: 5
                    });
                    break
            }
        }
    }, [notification]);

    return (
        <>{contextHolder}</>
    )
}


export default NotificationComponent