import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ISchool, ISchoolInfo} from "../model/school.model";

export const SCHOOL_LOCAL_STORAGE = 'SCHOOL'

const initialState: ISchool = {
    id: undefined,
    schoolName: "",
    schoolFiscalCode: "",
    schoolEmail: "",
    schoolPhone: undefined,
    schoolZone: undefined,
    contactPersonName: "",
    contactPersonFiscalCode: undefined,
    contactPersonEmail: "",
    contactPersonPhone: 0
}

const schoolSlice = createSlice({
    name: 'school',
    initialState,
    reducers: {
        save(state, action: PayloadAction<ISchool>) {
            state.id = action.payload.id
            state.schoolName = action.payload.schoolName
            state.schoolFiscalCode = action.payload.schoolFiscalCode
            state.schoolEmail = action.payload.schoolEmail
            state.schoolPhone = action.payload.schoolPhone
            state.schoolZone = action.payload.schoolZone
            state.contactPersonName = action.payload.contactPersonName
            state.contactPersonFiscalCode = action.payload.contactPersonFiscalCode
            state.contactPersonEmail = action.payload.contactPersonEmail
            state.contactPersonPhone = action.payload.contactPersonPhone

            localStorage.setItem(SCHOOL_LOCAL_STORAGE, JSON.stringify(state))
        },
        updateSchool(state, action: PayloadAction<ISchool>) {
            state.schoolName = action.payload.schoolName
            state.schoolFiscalCode = action.payload.schoolFiscalCode
            state.schoolEmail = action.payload.schoolEmail
            state.schoolPhone = action.payload.schoolPhone
            state.schoolZone = action.payload.schoolZone
            state.contactPersonName = action.payload.contactPersonName
            state.contactPersonFiscalCode = action.payload.contactPersonFiscalCode
            state.contactPersonEmail = action.payload.contactPersonEmail
            state.contactPersonPhone = action.payload.contactPersonPhone

            const school: ISchool | undefined = loadSchoolFromLocalStorage()
            if(school){
                const updatedSchool: ISchool = {
                    ...school,
                    schoolPhone: action.payload.schoolPhone,
                    schoolZone: action.payload.schoolZone,
                    contactPersonName: action.payload.contactPersonName,
                    contactPersonFiscalCode: action.payload.contactPersonFiscalCode,
                    contactPersonEmail: action.payload.contactPersonEmail,
                    contactPersonPhone: action.payload.contactPersonPhone
                }
                localStorage.setItem(SCHOOL_LOCAL_STORAGE, JSON.stringify(updatedSchool))
            }

        },
        updateSchoolInfo(state, action: PayloadAction<ISchoolInfo>) {
            state.id = action.payload.id
            state.schoolName = action.payload.schoolName
            state.schoolFiscalCode = action.payload.schoolFiscalCode
            state.schoolEmail = action.payload.schoolEmail

            const school: ISchool | undefined = loadSchoolFromLocalStorage()
            if(school){
                const updatedSchool: ISchool = {
                    ...school,
                    schoolName: action.payload.schoolName,
                    schoolFiscalCode: action.payload.schoolFiscalCode,
                    schoolEmail: action.payload.schoolEmail
                }
                localStorage.setItem(SCHOOL_LOCAL_STORAGE, JSON.stringify(updatedSchool))
            }

        },
        reset(state){
            state.id = undefined
            state.schoolName = ""
            state.schoolFiscalCode = ""
            state.schoolEmail = ""
            state.schoolPhone = undefined
            state.schoolZone = undefined
            state.contactPersonName = ""
            state.contactPersonFiscalCode = undefined
            state.contactPersonEmail = ""
            state.contactPersonPhone = 0
        }
    }
})

export const schoolActions = schoolSlice.actions
export const schoolReducer = schoolSlice.reducer

export const loadSchoolFromLocalStorage = () => {
    try {
        const school = localStorage.getItem(SCHOOL_LOCAL_STORAGE)
        return school ? JSON.parse(school) : undefined
    } catch (e) {
        return undefined
    }
}