import {Button, Modal} from "antd";
import React from "react";

interface Props {
    readonly isOpen: boolean
    readonly onCloseModal: () => void
}

const PrivacyModal = (props: Props) => {

    return <Modal title="Condizioni di privacy" open={props.isOpen} onCancel={props.onCloseModal} footer={[
        <Button key="submit" type="primary" onClick={props.onCloseModal}>
            Chiudi
        </Button>
    ]}>
        <p style={{textAlign: 'justify'}}>
            INFORMATIVA EX ART. 13 DEL REGOLAMENTO UE/2016/679 [GDPR] Gentile Utente, Desideriamo informarLa che
            il Regolamento UE/2016/679 “General Data Protection Regulation” (GDPR), di immediata applicazione
            anche in Italia prevede la tutela delle persone e di altri soggetti rispetto al trattamento dei
            propri dati personali. Nel rispetto della normativa indicata, il trattamento effettuato dal nostro
            ente sarà improntato ai principi di correttezza, liceità, trasparenza e di tutela della Sua
            riservatezza e dei Suoi diritti. Ai sensi dell’articolo 13 del GDPR, pertanto, Le comunichiamo
            quanto segue: I dati forniti verranno trattati, dietro Suo consenso, utilizzati per il solo fine
            dell’Iscrizione alla Competizione di Danza Sportiva alla cui lei, attraverso questa procedura, vuole
            partecipare. I dati personali saranno conservati per tutto il tempo necessario alla gestione delle
            iscrizioni della Competizione. Il conferimento dei dati è da intendersi obbligatorio per il
            raggiungimento delle finalità di suddetto servizio I dati forniti, terminato il periodo
            d’iscrizione, saranno comunicati solo ed esclusivamente all’Organizzatore della Competizione per la
            quale ci si sta iscrivendo, il quale li fornirà al Segretario di Gara nominato che potrà consultarli
            per l’inserimento degli stessi all’interno del software di verbalizzazione utilizzato, tutti i dati
            non saranno comunicati ad altri soggetti se non a quelli indicati, né saranno oggetto di diffusione
            salvo esplicita richiesta. Il trattamento non riguarderà dati personali rientranti nel novero dei
            dati “sensibili”, vale a dire “i dati personali idonei a rivelare l’origine razziale ed etnica, le
            convinzioni religiose, filosofiche o di altro genere le opinioni politiche, l’adesione a partiti,
            sindacati, associazioni od organizzazioni a carattere religioso, filosofico, politico o sindacale,
            nonché i dati personali idonei a rilevare lo stato di salute e la vita sessuale”. Il responsabile
            del trattamento è Bianchi Michael – C.F. BNCMHL91P07D612U contattabile all’indirizzo mail
            iscrizioni.jess@gmail.com Lei ha il diritto di revocare il consenso in qualsiasi momento senza
            pregiudicare la liceità del trattamento basata sul consenso prestato prima della revoca. Tale
            diritto potrà essere esercitato inviando la revoca del consenso all’indirizzo e-mail indicato nel
            precedente punto. Lei ha diritto di proporre reclamo al Garante per la protezione dei dati personali
            ovvero alla diversa autorità di controllo che dovesse essere istituita dal Decreto previsto dalla
            Legge Comunitaria 163/2017. Non esiste alcun processo decisionale automatizzato, né alcuna attività
            di profilazione di cui all’articolo 22, paragrafi 1 e 4 del GDPR. Qualora si volesse richiedere la
            cancellazione del dato inviato durante la procedura d’iscrizione alla Competizione inviare una mail
            a iscrizioni.jess@gmail.com ed utilizzare come oggetto: cancellazione dati personali modulo
            iscrizioni.
        </p>
    </Modal>
}

export default PrivacyModal