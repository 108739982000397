import {Button, Modal} from "antd";
import {Link} from "react-router-dom";
import React from "react";

interface HelpModalProps {
    readonly isHelpModalOpen: boolean
    readonly closeHelpModal: () => void
}

const HelpModal = (props: HelpModalProps) => {

    return <Modal title="Assistenza" open={props.isHelpModalOpen} onCancel={props.closeHelpModal} footer={[
        <Button key="help-modal-ok-button" type="primary" onClick={props.closeHelpModal}>
            Ok
        </Button>
    ]}>
        <div>
            <h5>
                Dove si trovano le iscrizioni effettuate?
            </h5>
            <p>
                Per controllare le iscrizioni effettuate: cliccare in alto il pulsante "Iscrizioni", selezionare
                l'evento e una tipologia di iscrizioni (es. SOLO) e cliccare ESPORTA (il pulsante blu).
            </p>
            <h5>
                Come si possono cancellare le iscrizioni effettuate?
            </h5>
            <p>
                Per eliminare le iscrizioni effettuate: cliccare in alto il pulsante "Iscrizioni", selezionare
                l'evento e una tipologia di iscrizioni (es. SOLO) e cliccare CANCELLA dall'iscrizione che si vuole
                eliminare e eventualmente inserire di nuovo l'iscrizione corretta.
            </p>
            <p>
                    <span>Per ulteriori problemi, contattare l'assistenza: <Link
                        to='#'
                        onClick={(e) => {
                            window.location.href = "mailto:iscrizioni.jess@gmail.com";
                            e.preventDefault();
                        }}
                    >
                    <span className="nav-text">iscrizioni.jess@gmail.com</span>
                </Link></span>
            </p>
        </div>
    </Modal>
}

export default HelpModal